<template>
  <Modal open @close="$emit('close')">
    <div>
      <iframe
        src="https://cdn.forms-content.sg-form.com/d1af8f73-a724-11ed-81a4-ee95405b94ba"
        width="800px"
        height="600px"
      />
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from "vue";
import Modal from "@/components/common/Modal";

export default defineComponent({
  emits: ["close", "created"],

  components: {
    Modal,
  },

  computed: {
    action() {
      if (!this.adapter) {
        return null;
      } else {
        return "Back";
      }
    },
  },

  data() {
    return {};
  },
});
</script>
