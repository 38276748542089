<template>
  <highcharts class="chart" :options="chartOptions" />
</template>

<script>
export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: "spline",
        },
        data: {
          csvURL: "https://demo-live-data.highcharts.com/vs-load.csv",
          seriesMapping: [
            {
              x: 0,
              y: 1,
            },
          ],
        },
        yAxis: {
          labels: {
            formatter: function () {
              return this.axis.defaultLabelFormatter.call(this);
            },
          },
        },
        title: {
          text: "Sin chart",
        },
      },
    };
  },
  methods: {
    changeContainerWidth() {
      //
    },
  },
  created() {
    let i = document.createElement("input");
    i.setAttribute("type", "color");
    i.type === "color"
      ? (this.colorInputIsSupported = true)
      : (this.colorInputIsSupported = false);
  },
  watch: {
    title(newValue) {
      this.chartOptions.title.text = newValue;
    },
    points(newValue) {
      this.chartOptions.series[0].data = newValue;
    },
    chartType(newValue) {
      this.chartOptions.chart.type = newValue.toLowerCase();
    },
    seriesColor(newValue) {
      this.chartOptions.series[0].color = newValue.toLowerCase();
    },
    animationDuration(newValue) {
      this.updateArgs[2].duration = Number(newValue);
    },
  },
};
</script>
