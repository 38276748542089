<template>
  <Modal open @close="$emit('close')">
    <div>
      <iframe
        src="https://cdn.forms-content.sg-form.com/e829aa20-a673-11ed-9fcf-be8d4cef91e9"
        width="800px"
        height="600px"
      />
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from "vue";
import Modal from "@/components/common/Modal";

export default defineComponent({
  emits: ["close", "created"],

  components: {
    Modal,
  },

  computed: {
    action() {
      if (!this.adapter) {
        return null;
      } else {
        return "Back";
      }
    },
  },

  data() {
    return {};
  },
});
</script>
