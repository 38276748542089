<template>
  <div>
    <!-- Page title & actions -->
    <div
      class="
        border-b border-gray-200
        px-4
        py-4
        sm:flex sm:items-center sm:justify-between sm:px-6
        lg:px-8
      "
    >
      <div class="flex-1 min-w-0">
        <link
          rel="stylesheet"
          href="https://assets.trueloader.com/dash-plain.css"
        />
        <section>
          <div class="section-inner-div">
            <div class="mobile-header">
              <img
                src="https://assets.trueloader.com/Trueloader-black-logo.png"
                style="width: auto; height: 50px"
                id="tl-logo-mob"
              />
              <img
                src="https://assets.trueloader.com/dash-mob-menu.svg"
                id="menu-icon"
              />
            </div>

            <div class="right-col">
              <div class="name-div-wrapper">
                <div class="col-95" style="width: 95%; padding-bottom: 20px">
                  <div class="light-blue-box" id="avatar-box">
                    <div
                      id="avatar-profile"
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        position: relative;
                      "
                    >
                      <img
                        src="https://assets.trueloader.com/dash-avatar.png"
                        style=""
                      />
                      <div style="display: flex; flex-direction: column">
                        <h3 class="site-heading-small">{{ user.name }}</h3>
                        <p style="color: #da5dce">
                          {{ user.email }}
                        </p>
                      </div>
                    </div>

                    <p style="margin: 20px 0">
                      We are working hard to make your journey with us more
                      comfortable.
                    </p>
                  </div>

                  <div
                    class="light-blue-box"
                    style="display: flex; flex-direction: row; margin-top: 20px"
                  >
                    <div
                      class="col-70"
                      style="
                        padding-right: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                      "
                    >
                      <h3 class="big-heading-blue" style="margin-bottom: 0px">
                        Going Live?
                      </h3>
                      <p style="margin: 20px 0; font-size: 11pt">
                        We support Shared and On-premises deployments, Our
                        Microservices can be deployed in Kubernetes using Helm,
                        which supports horizontal & vertical auto-scaling.
                      </p>
                    </div>
                    <div
                      class="col-30"
                      style="display: flex; flex-direction: column"
                    >
                      <img
                        src="https://assets.trueloader.com/Turbocharge.png"
                        style="width: 75%; margin-left: auto"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-65" style="padding: 20px; padding-top: 0px">
                  <div class="cta-box">
                    <img
                      src="https://assets.trueloader.com/04.png"
                      class="top-img"
                      style="display: none"
                    />
                    <img
                      src="https://assets.trueloader.com/dash-final-cta.png"
                      style="width: 100%"
                    />
                    <div id="cta-content">
                      <h2 class="big-heading-blue" style="text-align: center">
                        Welcome to the our family!
                      </h2>
                      <a
                        href="https://wiki.ex.trueloader.com"
                        class="primary-btn"
                        >Starting Guide</a
                      >
                      <p style="margin: 20px 0; font-size: 10pt; padding: 30px">
                        We need your help. Please provide us your True feedback
                        for us to optimize & improvise
                      </p>
                    </div>
                  </div>

                  <div
                    class="two-col"
                    style="display: flex; flex-direction: row"
                  >
                    <div
                      class="email-div"
                      style="
                        background-image: url(https://assets.trueloader.com/dash-email-bg.png) !important;
                      "
                    >
                      <img
                        src="https://assets.trueloader.com/dash-email.png"
                        style="width: 80px; margin-right: 15px"
                      />
                      <div style="display: flex; flex-direction: column">
                        <h3 class="site-heading-small" style="color: #fff">
                          Looking for Help?
                        </h3>
                        <p style="color: #fff">
                          <span
                            @click="openReachOut_1 = true"
                            style="cursor: pointer"
                            >Lets connect</span
                          >
                        </p>
                      </div>
                    </div>

                    <div class="email-div" style="margin-left: auto">
                      <img
                        src="https://assets.trueloader.com/dash-updates.png"
                        style="width: 80px; margin-right: 15px"
                      />
                      <div style="display: flex; flex-direction: column">
                        <h3 class="site-heading-small" style="color: #fff">
                          Status
                        </h3>
                        <p style="color: #fff">Active</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--class="name-div-wrapper"-->

              <div id="data-logo-div" style="margin-left: 40%">
                <!-- Slider Logo Section -->
                <div class="blue-grad-box" style="min-width: 250px">
                  <p
                    style="
                      color: #fff;
                      letter-spacing: 2px;
                      margin-bottom: 30px;
                      text-align: center;
                    "
                  >
                    Cant Find Your <br />Connector?
                  </p>
                  <span
                    @click="openRequest_Conns_1 = true"
                    style="cursor: pointer; color: #eef2ff"
                    >Request Connector</span
                  >
                </div>
              </div>
            </div>
            <!--class="right-col"-->
          </div>
          <!-- class="section-inner-div" -->
        </section>
      </div>
      <div class="mt-4 flex sm:mt-0 sm:ml-4">
        <button
          v-if="visible_components.btn_share"
          type="button"
          class="
            order-1
            ml-3
            inline-flex
            items-center
            px-4
            py-2
            border border-secondary-light
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-gray-700
            bg-white
            hover:bg-secondary
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-purple-500
            sm:order-0 sm:ml-0
          "
        >
          Share
        </button>
        <button
          v-if="visible_components.btn_create"
          type="button"
          class="
            order-0
            inline-flex
            items-center
            px-4
            py-2
            border border-transparent
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-white
            bg-purple-600
            hover:bg-purple-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-purple-500
            sm:order-1 sm:ml-3
          "
        >
          Create
        </button>
      </div>
    </div>

    <div
      v-if="visible_components.chart"
      class="px-4 mt-6 sm:px-6 lg:px-8 grid grid-cols-3 gap-6"
    >
      <div>
        <StockChart />
      </div>
      <div>
        <MapChart />
      </div>
      <div>
        <SplineChart />
      </div>
    </div>

    <div
      v-if="visible_components.chart"
      class="px-4 mt-6 sm:px-6 lg:px-8 grid grid-cols-3 gap-6"
    >
      <div>
        <StockChart />
      </div>
      <div>
        <MapChart />
      </div>
      <div>
        <SplineChart />
      </div>
    </div>
  </div>
  <ReachOut_1
    v-if="openReachOut_1"
    @close="openReachOut_1 = false"
    @created="handleReachOut_1"
  />
  <Request_Conns_1
    v-if="openRequest_Conns_1"
    @close="openRequest_Conns_1 = false"
    @created="handleRequest_Conns_1"
  />
</template>

<script>
import StockChart from "@/components/dashboard/StockChart";
import MapChart from "@/components/dashboard/MapChart";
import SplineChart from "@/components/dashboard/SplineChart";
import store from "@/store";
import ReachOut_1 from "@/components/misc/ReachOut_1";
import Request_Conns_1 from "@/components/misc/Request_Conns_1";

const user = store.getters["auth/user"];

export default {
  data() {
    return {
      visible_components: {
        btn_share: false,
        btn_create: false,
        chart: false,
        icons: true,
      },
      openReachOut_1: false,
      openRequest_Conns_1: false,
    };
  },
  components: {
    StockChart,
    MapChart,
    SplineChart,
    ReachOut_1,
    Request_Conns_1,
  },
  computed: {},
  setup() {
    console.log(user);
    return {
      user,
    };
  },
  methods: {
    handleReachOut_1() {
      this.openReachOut_1 = false;
    },
    handleRequest_Conns_1() {
      this.openReachOut_1 = false;
    },
  },
};
</script>
