<template>
  <div class="chartElem">
    <div class="row">
      <highcharts
        class="chart"
        :constructor-type="'chart'"
        :options="chartOptions"
      ></highcharts>
    </div>
  </div>
</template>
<script>
import Highcharts from "highcharts";
import dataModule from "highcharts/modules/data";
import drilldown from "highcharts/modules/drilldown";

drilldown(Highcharts);
dataModule(Highcharts);

let drilldownChart,
  drilldownEvent,
  drilldownLevel = 0;
const chartOptions = {
  chart: {
    type: "column",
    events: {
      drilldown: function (e) {
        if (!e.seriesOptions) {
          this.vueRef.updateGraph(true, this, e);
        }
      },
      drillup: function (e) {
        if (!e.seriesOptions.flag) {
          this.vueRef.drilldownLevel = e.seriesOptions._levelNumber;
          this.vueRef.updateGraph(false);
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    column: {
      stacking: "normal",
      events: {
        click: function () {
          return false;
        },
      },
    },
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        style: {
          textShadow: false,
          fontSize: "10px",
        },
      },
    },
  },
  legend: {
    enabled: false,
  },
  yAxis: {
    stackLabels: {
      enabled: false,
      style: {
        fontWeight: "bold",
        color: "gray",
      },
    },
  },
  title: {
    text: "Stacked Column Drilldown Chart",
    fontWeight: "bold",
  },
  xAxis: {
    title: {},
    type: "category",
  },
  series: [
    {
      name: "Outstanding",
      color: "rgb(102, 168, 255)",
      data: [
        {
          name: "English",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "105",
        },
        {
          name: "Social Science",
          y: 1,
          parentCategoryHierarchyId: "0",
          graphParentId: "119",
          drilldown: true,
        },
        {
          name: "Science",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "126",
        },
        {
          name: "Maths",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "139",
        },
        {
          name: "Hindi",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "146",
        },
      ],
    },
    {
      type: "column",
      name: "Very Good",
      color: "rgb(128, 183, 255)",
      data: [
        {
          name: "English",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "105",
        },
        {
          name: "Social Science",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "119",
          drilldown: true,
        },
        {
          name: "Science",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "126",
        },
        {
          name: "Maths",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "139",
        },
        {
          name: "Hindi",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "146",
        },
      ],
    },
    {
      type: "column",
      name: "Satisfactory",
      color: "rgb(179, 212, 255)",
      data: [
        {
          name: "English",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "105",
        },
        {
          name: "Social Science",
          y: 1,
          parentCategoryHierarchyId: "0",
          graphParentId: "119",
          drilldown: true,
        },
        {
          name: "Science",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "126",
        },
        {
          name: "Maths",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "139",
        },
        {
          name: "Hindi",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "146",
        },
      ],
    },
    {
      type: "column",
      name: "Needs Improvement",
      color: "rgb(204, 226, 255)",
      data: [
        {
          name: "English",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "105",
        },
        {
          name: "Social Science",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "119",
          drilldown: true,
        },
        {
          name: "Science",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "126",
        },
        {
          name: "Maths",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "139",
        },
        {
          name: "Hindi",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "146",
        },
      ],
    },
    {
      type: "column",
      name: "Not Performing",
      color: "rgb(230, 242, 255)",
      data: [
        {
          name: "English",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "105",
        },
        {
          name: "Social Science",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "119",
          drilldown: true,
        },
        {
          name: "Science",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "126",
        },
        {
          name: "Maths",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "139",
        },
        {
          name: "Hindi",
          y: 0,
          parentCategoryHierarchyId: "0",
          graphParentId: "146",
        },
      ],
    },
  ],
};

export default {
  data() {
    return {
      chartOptions,
      drilldownChart,
      drilldownEvent,
      drilldownLevel,
    };
  },
  mounted() {
    if (this.$children) {
      this.$children[0].chart.vueRef = this;
    }
  },
  methods: {
    updateGraph(isDrilldown, chart, e) {
      if (isDrilldown) {
        drilldownLevel++;
        drilldownChart = chart;
        drilldownEvent = e;
        if (drilldownLevel === 1) {
          var drilldowns = {
              "Social Science": {
                name: "Outstanding",
                color: "rgb(102, 168, 255)",
                data: [
                  {
                    name: "Geography",
                    y: 7,
                    drilldown: true,
                  },
                  {
                    name: "History",
                    y: 4,
                  },
                  {
                    name: "Civics",
                    y: 9,
                  },
                ],
              },
            },
            drilldowns2 = {
              "Social Science": {
                name: "Very Good",
                color: "rgb(128, 183, 255)",
                data: [
                  {
                    name: "Geography",
                    y: 4,
                    drilldown: true,
                  },
                  {
                    name: "History",
                    y: 8,
                  },
                  {
                    name: "Civics",
                    y: 2,
                  },
                ],
              },
            },
            drilldowns3 = {
              "Social Science": {
                name: "Satisfactory",
                color: "rgb(179, 212, 255)",
                data: [
                  {
                    name: "Geography",
                    y: 4,
                    drilldown: true,
                  },
                  {
                    name: "History",
                    y: 7,
                  },
                  {
                    name: "Civics",
                    y: 1,
                  },
                ],
              },
            },
            drilldowns4 = {
              "Social Science": {
                name: "Needs Improvement",
                color: "rgb(204, 226, 255)",
                data: [
                  {
                    name: "Geography",
                    y: 2,
                    drilldown: true,
                  },
                  {
                    name: "History",
                    y: 7,
                  },
                  {
                    name: "Civics",
                    y: 2,
                  },
                ],
              },
            },
            drilldowns5 = {
              "Social Science": {
                name: "Not Performing",
                color: "rgb(230, 242, 255)",
                data: [
                  {
                    name: "Geography",
                    y: 6,
                    drilldown: true,
                  },
                  {
                    name: "History",
                    y: 3,
                  },
                  {
                    name: "Civics",
                    y: 0,
                  },
                ],
              },
            },
            series = drilldowns[e.point.name],
            series2 = drilldowns2[e.point.name],
            series3 = drilldowns3[e.point.name],
            series4 = drilldowns4[e.point.name],
            series5 = drilldowns5[e.point.name];
          chart.addSingleSeriesAsDrilldown(e.point, series);
          chart.addSingleSeriesAsDrilldown(e.point, series2);
          chart.addSingleSeriesAsDrilldown(e.point, series3);
          chart.addSingleSeriesAsDrilldown(e.point, series4);
          chart.addSingleSeriesAsDrilldown(e.point, series5);
          chart.applyDrilldown();
        } else if (drilldownLevel === 2) {
          var drilldown1 = {
            Geography: {
              name: "Yes",
              stacking: "percent",
              color: "red",
              data: [
                {
                  name: "Q1",
                  y: 1,
                },
                {
                  name: "Q2",
                  y: 2,
                },
                {
                  name: "Q3",
                  y: 3,
                },
                {
                  name: "Q4",
                  y: 4,
                },
              ],
            },
          };
          var drilldown2 = {
            Geography: {
              name: "No",
              stacking: "percent",
              color: "green",
              data: [
                {
                  name: "Q1",
                  y: 1,
                },
                {
                  name: "Q2",
                  y: 2,
                },
                {
                  name: "Q3",
                  y: 3,
                },
                {
                  name: "Q4",
                  y: 4,
                },
              ],
            },
          };
          var drilldown3 = {
            exampleDrilldown: {
              type: "line",
              name: "Example",
              color: "black",
              data: [
                {
                  name: "Q1",
                  y: 10,
                },
                {
                  name: "Q2",
                  y: 20,
                },
                {
                  name: "Q3",
                  y: 30,
                },
                {
                  name: "Q4",
                  y: 40,
                },
              ],
            },
          };
          chart.addSingleSeriesAsDrilldown(e.point, drilldown1[e.point.name]);
          chart.addSingleSeriesAsDrilldown(e.point, drilldown2[e.point.name]);
          chart.addSingleSeriesAsDrilldown(
            e.point,
            drilldown3["exampleDrilldown"]
          );
          console.log(e);
          chart.applyDrilldown();
        }
      }
    },
  },
  computed: {},
};
</script>
