<template>
  <div>
    <highcharts class="hc" :options="chartOptions" ref="chart" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      chartOptions: {
        series: [
          {
            data: [1, 2, 3],
          },
        ],
      },
    };
  },
};
</script>
